@import "normalize.css";



/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: 
       local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
       url('./fonts/source-sans-pro-v13-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-sans-pro-v13-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-600 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
       url('./fonts/source-sans-pro-v13-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-sans-pro-v13-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'),
       url('./fonts/source-sans-pro-v13-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-sans-pro-v13-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-600italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  src: local('Source Sans Pro SemiBold Italic'), local('SourceSansPro-SemiBoldItalic'),
       url('./fonts/source-sans-pro-v13-latin-600italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-sans-pro-v13-latin-600italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-code-pro-300 - latin */
@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 300;
  src: local('Source Code Pro Light'), local('SourceCodePro-Light'),
       url('./fonts/source-code-pro-v11-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-code-pro-v11-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-code-pro-300italic - latin */
@font-face {
  font-family: 'Source Code Pro';
  font-style: italic;
  font-weight: 300;
  src: local('Source Code Pro Light Italic'), local('SourceCodePro-LightIt'),
       url('./fonts/source-code-pro-v11-latin-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-code-pro-v11-latin-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-code-pro-regular - latin */
@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Source Code Pro Regular'), local('SourceCodePro-Regular'),
       url('./fonts/source-code-pro-v11-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-code-pro-v11-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-code-pro-italic - latin */
@font-face {
  font-family: 'Source Code Pro';
  font-style: italic;
  font-weight: 400;
  src: local('Source Code Pro Italic'), local('SourceCodePro-It'),
       url('./fonts/source-code-pro-v11-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-code-pro-v11-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-code-pro-700italic - latin */
@font-face {
  font-family: 'Source Code Pro';
  font-style: italic;
  font-weight: 700;
  src: local('Source Code Pro Bold Italic'), local('SourceCodePro-BoldIt'),
       url('./fonts/source-code-pro-v11-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-code-pro-v11-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-code-pro-700 - latin */
@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 700;
  src: local('Source Code Pro Bold'), local('SourceCodePro-Bold'),
       url('./fonts/source-code-pro-v11-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-code-pro-v11-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* 
 * Remember font weights:

    font-weight: 100;
    font-weight: 200;
    font-weight: 300;
    font-weight: 400; // normal
    font-weight: 500;
    font-weight: 600;
    font-weight: 700; // bold
    font-weight: 800;
    font-weight: 900;

 */

/* 
 * define extend placeholders
 *
 * https://github.com/travco/postcss-extend
 *
 */
.font-sans {
  font-family: 'Source Sans Pro',
               /* System Fonts as used by Medium and WordPress */
               -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: normal; 
}

.font-mono {
  font-family: 
               'Source Code Pro',
               Consolas, "Andale Mono WT", "Andale Mono", 
               "Lucida Console", "Lucida Sans Typewriter", 
               "DejaVu Sans Mono", "Bitstream Vera Sans Mono", 
               "Liberation Mono", "Nimbus Mono L", Monaco, 
               "Courier New", Courier, monospace;
  font-weight: normal;
}

body {
  @extend .font-mono;
  font-size: 16px;
  line-height: 1.4;
  background-color: lightgrey;
  color: white;
  margin: 0;
}

.asciiland {
  background-color: #d64444;
  padding: 1ch;
}

@media screen and (min-width: 40em) {
  /* .asciiland {        */
  /*   position: fixed;  */
  /*   top: 2ch;         */
  /*   left: 2ch;        */
  /*   right: 2ch;       */
  /*   bottom: 2ch;      */
  /*   overflow-y: auto; */
  /*   padding: 1ch;     */
  /* }                   */
}

a {
  color: white;
}
a:hover, a:focus {
  text-decoration: none;
}

pre {
  @extend .font-mono;
}

hr {
  color: white;
  border: 0;
  border-bottom: 1px dashed white;
}

.text-wrap {
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-size: 1em;
}

h2, .h2 {
  text-transform: uppercase;
  border-left: 2px solid white;
  padding-left: 1ch;
  margin-left: -2px;
}
h2::before, .h2::before {
  /* content: '— '; */
}

.p {
  text-transform: none;
  border-left: 0;
  padding-left: 0;
  margin-left: 0;
}

ul {
  list-style: none;
  padding-left: 4ch;
}
li::before {
  content: '- ';
}
li {
  text-indent: -2ch;
}
figure {
  margin: 0;
}

.small {
  font-size: 0.8em;
}
.text-is-centered {
  text-align: center;
}
.text-is-right {
  text-align: right;
}
.margin-bottom-xl {
  margin-bottom: calc(7em * 1.4);
}
.l-content {
  max-width: 72ch;

  margin: 10em auto;
}

.c-content {
  margin: 0 1ch;
}

.c-content figure img {
  width: 100%;
  /* width: calc(100% - 8ch); */
  /* padding: 4ch; */
  margin: 1.4em 0;
  /* border: 1px solid white; */
}

.c-content .thumbs img {
  padding: 1ch;
  margin:0;
  width: calc(50% - 3ch);
  min-width: 300px;
  text-align: center;
}

.chars-box {
  line-height: 1;
  display: inline-block;
  /* width: 4.5ch; */
  width: calc(3ch + 1ch + 1.25ch);
  letter-spacing: 0.50ch;
  overflow-wrap: break-word;
  text-transform: lowercase;
  padding: 0.75ch;
  /* padding-right: 1ch; */
  padding-left: 1.25ch;
  padding-bottom: 1.25ch;
  border: 2px solid transparent;
}
a.chars-box {
  text-decoration: none;
}
a.chars-box:hover {
  border-bottom: 2px solid white;
}
  .one-char-space {
    display: inline-block;
    width: 1.5ch;
  }
  .two-char-space, .two-chars-space {
    display: inline-block;
    width: 3ch;
  }


.asciiart {
  width: 100%;
  margin: 0 auto;

  overflow-wrap: normal;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.7em;
  line-height: 0.6;
}



